import React, { lazy } from 'react'
import './home.css'

import { HiArrowLongRight } from 'react-icons/hi2'
import { FaPuzzlePiece } from 'react-icons/fa'
import { MdLock, MdComment } from 'react-icons/md'
import { BsTelephone } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { FaArrowRightLong } from 'react-icons/fa6'

import AboutImg from '../Images/homeImages/Home-about-us.png'
import ArchitectureImg from '../Images/homeImages/Home-architecture.png'
import InteriorImg from '../Images/homeImages/Home-interior.png'
import WorkImg1 from '../Images/homeImages/works-1.png'
import WorkImg2 from '../Images/homeImages/works-2.png'
import WorkImg3 from '../Images/homeImages/works-3.png'
import Star from '../Images/star.png'

import Slideshow from '../Components/Slideshow'
import '../Components/Slideshow.css'

const ProjectCard = lazy(() => import('../Components/ProjectCard'))

export default function Home() {
    return (
        <>
            {/* Banner */}
            <div className="banner">
                <Slideshow />
                {/* <img src={require("../Images/homeImages/Banner-1.webp")} alt="Banner" width='100%' height={height} /> */}
                <div className="container banner-box">
                    <div className="bg-hero-text col-md-6 col-10 py-md-5 py-4 mx-auto text-center">
                        <h1 className='text-white'>Designing Dreams,</h1>
                        <h3 className='text-white'>Building Wonders!</h3>
                    </div>
                </div>
            </div>


            {/* Message  */}
            <div className="bg-banner-bottom">
                <div className="container">
                    <div className="col-lg-8 col-md-12 msg col-11 mx-auto">
                        <p className='mb-0 text-white'>
                            At Chhavi Studio, we design dreams where architectural mastery creates captivating spaces that inspire awe. Experience a world of elegance, innovation, and extraordinary achievements in every project we undertake.
                        </p>
                    </div>
                </div>
            </div>


            {/* About Us  */}
            <div className="row w-100">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-6 about-img">
                        <img loading='lazy' src={AboutImg} alt="About Us" width='100%' className='lazy' />
                    </div>
                    <div className="col-md-6">
                        <div className="home-about">
                            <h1 className='mb-lg-5 mb-md-3'>About Us</h1>
                            <p>At Studio Chhavi, we passionately craft spaces that speak to your soul, telling your unique story through mindful planning and thoughtful design. </p>
                            <p className='mb-md-5 mb-4'>Our vision is to curate "timeless meaningful spaces" that infuse harmony, happiness, and peace into the lives of our clients, creating not just architecture, but a lifestyle that enriches the occupants' daily experiences.</p>
                            <a href='/about-us' className='btn btn-secondary px-5 mb-5'>
                                Know More <HiArrowLongRight />
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            {/* Our projects  */}
            <div className="bg-home-projects">
                <div className="container text-center pt-md-5 pt-3">
                    <h1 className="mb-4">Our Highlights</h1>
                    <p className='light-text pb-md-5 pb-3 mb-0'>Discover Our Architectural Masterpieces: Showcasing Elegance and Innovation in Every Detail.</p>
                </div>

                <div className="container d-flex flex-wrap justify-content-between">
                    <div className=""></div>
                    <div className="pb-3">
                        <a href="/our-projects" className="text-black text-decoration-none">
                            View All <HiArrowLongRight style={{ marginTop : '-4px'}} className='ms-1' />
                        </a>
                    </div>
                </div>

                <ProjectCard />
            </div>


            {/* Architecture  */}
            <div className="bg-architecture architecture">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-6">
                        <h1 className='font-big d-block d-md-none'>ARCHITECTURE</h1>
                        <p className="light-color d-block d-md-none">Our Services</p>
                        <h1 className='mb-4 fw-bold d-block d-md-none'>Architecture Service</h1>
                        <div className="architecture-img">
                            <div className="inside-img">
                                <img loading='lazy' src={ArchitectureImg} alt="" width='100%' className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 architecture-text">
                        <h1 className='font-big d-none d-md-block'>ARCHITECTURE</h1>
                        <div className="ps-md-4 pt-lg-5 inside-div">
                            <p className="light-color d-none d-md-block">Our Services</p>
                            <h1 className='mb-4 fw-bold d-none d-md-block'>Architecture Service</h1>
                            <h3 className='mb-4'>We make your comfort our top priority</h3>
                            <p>Transforming Visions into Breathtaking Realities - Unleashing the Power of Design.</p>

                            <div className="container p-0 d-flex flex-wrap ">
                                <div className="col-lg-4 col-md-6 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='10%' className="img-fluid me-2" />
                                    Planning
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='10%' className="img-fluid me-2" />
                                    Consultation
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='10%' className="img-fluid me-2" />
                                    Execution
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='10%' className="img-fluid me-2" />
                                    Structural Design
                                </div>
                                <div className="col-lg-5 col-md-8 col-12 py-1 star last-star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='8%' className="img-fluid me-2" />
                                    Construction Service
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Interior  */}
            <div className="bg-architecture pb-5">
                <div className="container d-flex flex-wrap">
                    <div className="col-md-6 d-block d-md-none">
                        <h1 className='font-big ps-md-5'>INTERIOR</h1>
                        <p className="light-color">Our Services</p>
                        <h1 className='mb-4 fw-bold'>Interior Design Service</h1>
                        <div className="architecture-img">
                            <div className="inside-img">
                                <img loading='lazy' src={InteriorImg} alt='Interior' width='100%' className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 architecture-text">
                        <h1 className='font-big ps-md-5 d-none d-md-block'>INTERIOR</h1>
                        <div className="ps-md-4 pt-lg-5 inside-div">
                            <p className="light-color d-none d-md-block">Our Services</p>
                            <h1 className='mb-4 fw-bold d-none d-md-block'>Interior Design Service</h1>
                            <h3 className='mb-4'>Your contentment is our primary focus.</h3>
                            <p>Elevating Spaces with Timeless Elegance - Where Comfort Meets Aesthetics.</p>

                            <div className="container p-0 d-flex flex-wrap ">
                                <div className="col-lg-6 col-md-8 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='7%' className="img-fluid me-2" />
                                    Planning
                                </div>
                                <div className="col-lg-6 col-md-8 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='7%' className="img-fluid me-2" />
                                    Designing
                                </div>
                                <div className="col-lg-6 col-md-8 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='7%' className="img-fluid me-2" />
                                    Vaastu Consultation
                                </div>
                                <div className="col-lg-6 col-md-8 col-12 py-1 star">
                                    <img loading='lazy' src={Star} alt="Star Img" width='7%' className="img-fluid me-2" />
                                    End to end Execution
                                </div>
                            </div>

                        </div>
                        

                    </div>

                    <div className="col-md-6 d-none d-md-block">
                        <div className="architecture-img">
                            <div className="inside-img">
                                <img loading='lazy' src={InteriorImg} alt='Interior' width='100%' className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* How we work */}
            <div className="process">
                <div className="container text-center">
                    <h1 className="pt-md-5 pt-4">How We Work?</h1>
                    <p className="pb-md-5 pb-4 mb-0">With our 3 step process we'll collaborate closely, and witness your dream space come to life with our personalised design process.</p>
                </div>

                <div className="container d-flex flex-wrap justify-content-around mt-md-5 py-lg-5 pb-md-5">
                    <div className="col-lg-3 col-md-4 px-lg-0 px-md-2 px-0 py-5 py-md-0 work ">
                        <img loading='lazy' src={WorkImg1} alt="Work 1" className="img-fluid w-100" />
                        <p className="number">1</p>
                        <div className="border-bootom">
                            <div className="text-center mt-2 p-2 bg-work">
                                <h5 className='text-white'>Insightful Consultation</h5>
                                <p className='mb-0 text-white'>Through meaningful interactions with clients, we gain valuable insights to understand their unique preferences and requirements, forming the bedrock of our design approach.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 px-lg-0 px-md-2 px-0 py-5 py-md-0 work work-center">
                        <img loading='lazy' src={WorkImg2} alt="Work 2" className="img-fluid w-100" />
                        <p className="number">2</p>
                        <div className="border-bootom">
                            <div className="text-center mt-2 p-2 bg-work">
                                <h5 className='text-white'>Creative Ideation</h5>
                                <p className='mb-0 text-white'>Unleashing boundless creativity, we conceptualize awe-inspiring designs that breathe life into spaces, reflecting artistry and innovation tailored to each client's vision.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 px-lg-0 px-md-2 px-0 py-5 py-md-0 work ">
                        <img loading='lazy' src={WorkImg3} alt="Work 3" className="img-fluid w-100" />
                        <p className="number">3</p>
                        <div className="border-bootom">
                            <div className="text-center mt-2 p-2 bg-work">
                                <h5 className='text-white'>Precise Execution</h5>
                                <p className='mb-0 text-white'>With meticulous attention to detail and  craftsmanship, we bring the envisioned design to life, ensuring flawless implementation and exceeding expectations at every turn.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Why us  */}
            <div className="why-us">
                <div className="text-center py-5">
                    <div className='after-heading'>
                        <h1>Why Us?</h1>
                    </div>
                </div>
                <div className="container d-flex flex-wrap mb-5">
                    <div className="col-md-4 sec-why">
                        <FaPuzzlePiece fill='#D0D5DD' className='ms-md-2 ms-1' />
                        <div className="internal-why">
                            <h5 className='heading-style ps-2'>Design Excellence</h5>
                            <p className='ps-2'>Our team of visionary architects and designers consistently deliver design excellence, transforming concepts into awe-inspiring realities that surpass expectations.</p>
                        </div>
                    </div>

                    <div className="col-md-4 sec-why">
                        <MdLock className='ms-md-2 ms-1' fill='#D0D5DD' />
                        <div className="internal-why border-start">
                            <h5 className='heading-style ms-lg-3 ms-md-1 ps-2'>Protecting Your Future</h5>
                            <p className='ms-lg-3 ms-md-1 ps-2'>We go the extra mile to implement robust security features, safeguarding your investments and providing a sense of confidence in every construction endeavor.</p>
                        </div>
                    </div>

                    <div className="col-md-4 sec-why">
                        <MdComment className='ms-md-2 ms-1' fill='#D0D5DD' />
                        <div className="internal-why border-start">
                            <h5 className='heading-style ms-lg-3 ms-md-1 ps-2'>Seamless Communication</h5>
                            <p className='ms-lg-3 ms-md-1 ps-2'>Stay connected with our team through instant chat and messaging, ensuring a smooth and efficient collaboration throughout the project.</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Contact us home  */}
            <div className="back-light">
                <div className="container d-md-flex ps-4 ps-md-0 flex-wrap justify-content-around contact-home">
                    <div className="col-lg-4 col-md-5">
                        <p className="contact-font">Want to work with us?</p>
                    </div>
                    <div className="col-lg-4 col-md-5 faint-txt">
                        <p>Reach us -</p>
                        <p className='mb-1 fs-md-5'>
                            <a href="tel:+917745097565" className='faint-txt'><BsTelephone className='me-3' fill='#667085' /> +91 7745097565</a>
                        </p>
                        <p className=' fs-md-5'>
                            <a href="mailto: ar.srushtigmemane23@gmail.com" className='faint-txt'><AiOutlineMail className='me-3' fill='#667085' /> ar.srushtigmemane23@gmail.com</a>
                        </p>
                        <a href='/contact-us' className='btn btn-primary px-5 mt-3 mt-lg-4'>Contact Us <FaArrowRightLong className='ms-2' fill='white' /></a>
                    </div>
                </div>
            </div>
        </>
    )
}
