import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Suspense, lazy } from 'react';


import Home from './Pages/home'


const Header = lazy(() => import('./Components/Header'))
const Footer = lazy(()=>import('./Components/Footer'))


const ContactUs = lazy(()=>import('./Pages/contact-us'))
const Projects = lazy(()=>import('./Pages/projects'))
const AboutUs = lazy(()=>import('./Pages/About-us'))

const renderLoader = () => <div className="container"><div className="col-3 text-center mx-auto mar-y"><div className="loader"></div></div></div>


function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={renderLoader()} >
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-projects" element={<Projects />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  )
}

export default App;

