import './Slideshow.css';
import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

// Images 
import Banner1 from '../Images/homeImages/Banner-1.webp'
import Banner2 from '../Images/homeImages/Banner-2.webp'
import Banner3 from '../Images/homeImages/Banner-3.webp'
import Banner4 from '../Images/homeImages/Banner-4.webp'

export default function Slideshow(){
  
  const [imageNum, setImageNum] = useState(1);

  let width = window.innerWidth
  let height

  if (width > 1200) {
    height = 700
  }else if(width > 480 && width < 1200){
    height = 450
  }else{
    height = 300
  }

  const sliderImages = [ 
    {
      url: Banner1,
    },
    {
      url: Banner2,
    },
    {
      url: Banner3,
    },
    {
      url: Banner4,
    }
  ];



  let widthh = "100%";
  
  return (
    <div className='slideshow'>
      <SimpleImageSlider
        width={widthh}
        height={height}
        images={sliderImages}
        showBullets={true}
        showNavs={height < 500 ? false : true}
        autoPlay={true}
        onStartSlide = {(index, length) => {
          setImageNum(index);
        }}
        autoPlayDelay = {10}
      />

      {
        imageNum && (<></>)
      }
      
    </div>
  );
}